import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="counter"
export default class extends Controller {
  static targets = ["count"]
  static values = {
    initial: Number,
    interval: Number,
    step: Number
  }

  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    this.intersectionObserver.observe(this.element)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element)
  }

  // Private

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      let countNumber = this.countTarget
      let initial = this.initialValue
      let interval = this.intervalValue
      let step = this.stepValue

      let counts=setInterval(updated, interval);
      let upto=0;
      function updated(){
        var count= countNumber;
        if((step - Math.floor(step)) !== 0)
        {
          count.innerHTML=upto.toFixed(1);
        } else {
          count.innerHTML=upto;
        }
        upto += step
        if(upto>initial)
        {
            clearInterval(counts);
            count.innerHTML = initial
        }
      }
    })
  }
}
