import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home-news-beat"
export default class extends Controller {
  static targets = ["icon"]

  hover() {
    let icon = this.iconTarget
    icon.classList.add("fa-beat");
  }

  unhover() {
    let icon = this.iconTarget
    icon.classList.remove("fa-beat");
  }
}
