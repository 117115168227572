import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-top"
export default class extends Controller {
  static targets = ["scroll", "contact", "icon"]

  connect() {
    let scrollButton = this.scrollTarget
    let contactButton = this.contactTarget
    
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (currentScrollPos < 10) {
        scrollButton.classList.remove("scroll-button-unhide");
        scrollButton.classList.add("scroll-button-hide");

        contactButton.classList.remove("contact-button-translate");
        contactButton.classList.add("contact-button-untranslate");

      } else {
        scrollButton.classList.add("scroll-button-unhide");
        scrollButton.classList.remove("scroll-button-hide");

        contactButton.classList.remove("contact-button-untranslate");
        contactButton.classList.add("contact-button-translate");
      }
    }
  }

  click() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  hover() {
    let icon = this.iconTarget
    icon.classList.add("fa-bounce");
  }

  unhover() {
    let icon = this.iconTarget
    icon.classList.remove("fa-bounce");
  }

}
