import { Controller } from "@hotwired/stimulus";
import Swal from 'sweetalert2';

// Connects to data-controller="pop-up"
export default class extends Controller {
  static values = {
    title: String,
    paragraph: String,
    icon: String,
    iconColor: String,
  }

  alert() {
    const title = this.titleValue
    const paragraph = this.paragraphValue
    const icon = this.iconValue
    const iconColor = this.iconColorValue

    Swal.fire({
      title: title,
      text: paragraph,
      icon: 'warning',
      iconColor: iconColor,
      iconHtml: icon,
      confirmButtonText: '<i class="fa-solid fa-left-long fa-xl"></i>',
      confirmButtonColor: iconColor
    })
  }

}
