import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="openning-hours"
export default class extends Controller {
  static targets = ["text", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday", "link", "list", "open", "close"]

  connect() {

    var day = new Date()


    switch (day.getDay()) {
      case 0:
        this.openTarget.classList.add("d-none");
        this.sundayTarget.classList.add("fw-bold");
        break;
      case 1:
        this.closeTarget.classList.add("d-none");
        this.mondayTarget.classList.add("fw-bold");
        break;
      case 2:
        this.closeTarget.classList.add("d-none");
        this.tuesdayTarget.classList.add("fw-bold");
        break;
      case 3:
        this.closeTarget.classList.add("d-none");
        this.wednesdayTarget.classList.add("fw-bold");
        break;
      case 4:
        this.closeTarget.classList.add("d-none");
        this.thursdayTarget.classList.add("fw-bold");
        break;
      case 5:
        this.closeTarget.classList.add("d-none");
        this.fridayTarget.classList.add("fw-bold");
        break;
      case 6:
        this.openTarget.classList.add("d-none");
        this.saturdayTarget.classList.add("fw-bold");
        break;
      default:
        this.textTarget.innerText = "Check our opening hours below";
    }

  }

  disable() {
    this.linkTarget.classList.add("d-none")
  }

  able() {
    this.linkTarget.classList.remove("d-none");
    this.listTarget.classList.remove("show")
  }

}
